import acura from "../images/logos/acura.png";
import alfaromeo from "../images/logos/alfaromeo.png";
import astonmartin from "../images/logos/astonmartin.png";
import audi from "../images/logos/audi.png";
import bentley from "../images/logos/bentley.png";
import bmw from "../images/logos/bmw.png";
import bugatti from "../images/logos/bugatti.png";
import cadillac from "../images/logos/cadillac.png";
import chrysler from "../images/logos/chrysler.png";
import corvette from "../images/logos/corvette.png";
import dodge from "../images/logos/dodge.png";
import ferrari from "../images/logos/ferrari.png";
import ford from "../images/logos/ford.png";
import honda from "../images/logos/honda.png";
import hyundai from "../images/logos/hyundai.png";
import jaguar from "../images/logos/jaguar.png";
import jeep from "../images/logos/jeep.png";
import kia from "../images/logos/kia.png";
import lamborghini from "../images/logos/lamborghini.png";
import maserati from "../images/logos/maserati.png";
import mazda from "../images/logos/mazda.png";
import nissan from "../images/logos/nissan.png";
import porsche from "../images/logos/porsche.png";
import subaru from "../images/logos/subaru.png";
import tesla from "../images/logos/tesla-logo-2007-full-640.png";
import toyota from "../images/logos/toyota-logo-2020-europe-640.png";

const carImages = {
  Acura: acura,
  AlfaRomeo: alfaromeo,
  AstonMartin: astonmartin,
  Audi: audi,
  Bentley: bentley,
  BMW: bmw,
  Bugatti: bugatti,
  Cadillac: cadillac,
  Chrysler: chrysler,
  Corvette: corvette,
  Dodge: dodge,
  Ferrari: ferrari,
  Ford: ford,
  Honda: honda,
  Hyundai: hyundai,
  Jaguar: jaguar,
  Jeep: jeep,
  Kia: kia,
  Lamborghini: lamborghini,
  Maserati: maserati,
  Mazda: mazda,
  Nissan: nissan,
  Porsche: porsche,
  Subaru: subaru,
  Tesla: tesla,
  Toyota: toyota
};

const recommendCars = [
  {
    id: "01",
    carName: "Continental Gt",
    location: "US-GA",
    isClient: "Yes",
    make: "Bentley",
    plate: "PNX9557",
  },
  {
    id: "02",
    carName: "Mazda CX-3",
    location: "US-GA",
    isClient: "No",
    make: "Mazda",
    plate: "MNO9872",
  },
  {
    id: "03",
    carName: "Dodge Viper",
    location: "US-GA",
    isClient: "Yes",
    make: "Dodge",
    plate: "STU7531",
  },
  {
    id: "04",
    carName: "Audi A4",
    location: "US-GA",
    isClient: "Yes",
    make: "Audi",
    plate: "ABC700555",
  },
  {
    id: "05",
    carName: "Honda Civic",
    location: "US-GA",
    isClient: "Yes",
    make: "Honda",
    plate: "AFZ9801",
  }
];

recommendCars.forEach(car => {
  car.imgUrl = carImages[car.make];
});

console.log(recommendCars);

export default recommendCars;
