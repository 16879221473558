import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import '../styles/carItem.css';

const CarItem = ({ item, index, moveCar, isSelected, onCarClick }) => {
  const { category, type, plate, imgUrl, imgUrl2, carName, location, date } = item;
  const upperCasePlate = plate.toUpperCase();
  const upperCaseLocation = location.toUpperCase();

  const [{ isDragging }, drag] = useDrag({
    type: 'CAR',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'CAR',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveCar(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const ref = React.useRef(null);
  drag(drop(ref));

  const handleClick = () => {
    onCarClick(carName);
  };

  return (
    <div
      ref={ref}
      className={`car__item ${isDragging ? 'dragging' : ''} ${isSelected ? 'selected' : ''}`}
      onClick={handleClick}
    >
      <div className="car__item-top">
        <div className="car__item-tile">
          <h3>{carName}</h3>
          <span>
            <i className="ri-list-ordered"></i>
          </span>
        </div>
        <p>{category}</p>
      </div>

      <Tooltip
        html={<img src={imgUrl2} alt={carName} style={{ width: '100%' }} />}
        position="bottom"
        trigger="mouseenter"
        interactive
        arrow={true}
      >
        <div className="car__img" style={{ height: '200px' }}>
          <img
            src={imgUrl}
            alt={carName}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </div>
      </Tooltip>

      <div className="car__item-bottom">
        <div className="car__bottom-left">
          <p>
            <i className="ri-map-2-line"></i> {upperCaseLocation}
          </p>
        </div>
        <p className="car__rent plate">{upperCasePlate}</p>
      </div>
      <div className="car__item-bottom">
        <div className="car__bottom-left">
          <p>
            <i className="ri-time-line"></i> {date}
          </p>
        </div>
        <div className="car__bottom-left">
          <p>
            <i className="ri-car-washing-fill"></i>
            {type}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CarItem;
