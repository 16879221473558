import React from "react";

const RecommendCarCard = (props) => {
  const { carName, isClient, imgUrl, location, plate } = props.item;
  return (
    <div className="recommend__car-card">
      <div className="recommend__car-top">
        <h5>
          <span>
            <i className="ri-user-3-fill"></i>
          </span>
          {plate}
        </h5>
      </div>

      <div className="recommend__car-img">
        <img src={imgUrl} alt="" />
      </div>
      <div className="recommend__car-bottom">
        <h4>{carName}</h4>
        <div className="recommend__car-other">
          <div className="recommend__icons">
            <p>
              <i className="ri-repeat-line"></i>
              {isClient}
            </p>
            <p>
              <i className="ri-map-pin-line"></i>
              {location}
            </p>
            <p>
              <i className="ri-caravan-fill"></i>
              {" No"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendCarCard;
