import React, { useState, useEffect } from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import "../styles/clients.css";
import fetchBookingCarsFromApi from "../assets/dummy-data/client-cars.js"; // Adjust the path if needed
import CarItem from "../components/UI/CarItem.jsx";

const Clients = () => {
  const [carData, setCarData] = useState([]);
  const [selectedCarIndex, setSelectedCarIndex] = useState(null); // Track the index of the selected car
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [selectedCarName, setSelectedCarName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchBookingCarsFromApi();
        if (data && data.length > 0) {
          setCarData(data);
        }
      } catch (error) {
        console.error("Error fetching client cars:", error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 30000);
    return () => clearInterval(interval);
  }, []);

  const moveCar = (dragIndex, hoverIndex) => {
    const draggedCar = carData[dragIndex];
    const updatedCars = [...carData];
    updatedCars.splice(dragIndex, 1);
    updatedCars.splice(hoverIndex, 0, draggedCar);
    setCarData(updatedCars);
  };

  const toggleClicked = (buttonText) => {
    if (selectedButtons.includes(buttonText)) {
      setSelectedButtons(selectedButtons.filter(text => text !== buttonText));
    } else {
      setSelectedButtons([...selectedButtons, buttonText]);
    }
  };

  const handleCarClick = (carName, index) => {
    setSelectedCarName(carName); // Update the selected car name
    setSelectedCarIndex(index); // Update the selected car index
  };

  return (
    <div className="clients">
      <div className="client__wrapper">
        <div className="client__car-list">
          <DndProvider backend={HTML5Backend}>
            {carData.map((item, index) => (
              <CarItem
                key={item.id}
                index={index}
                item={item}
                moveCar={moveCar}
                isSelected={index === selectedCarIndex}
                onCarClick={(carName) => handleCarClick(carName, index)}
              />
            ))}
          </DndProvider>
        </div>
        <div className="client__content">
          
          <div className="client__buttons">
            {/* Buttons for the rest of the services */}
            <button className={"big-button" + (selectedButtons.includes("Tire Shine") ? " clicked" : "")} onClick={() => toggleClicked("Tire Shine")}>Tire Shine</button>
            <button className={"big-button" + (selectedButtons.includes("Top brush") ? " clicked" : "")} onClick={() => toggleClicked("Top brush")}>Top brush</button>
            <button className={"big-button" + (selectedButtons.includes("Tire Brush") ? " clicked" : "")} onClick={() => toggleClicked("Tire Brush")}>Tire Brush</button>
            <button className={"big-button" + (selectedButtons.includes("Grill Grush") ? " clicked" : "")} onClick={() => toggleClicked("Grill Grush")}>Grill Grush</button>
            <button className={"big-button" + (selectedButtons.includes("Buff") ? " clicked" : "")} onClick={() => toggleClicked("Buff")}>Buff</button>
            <button className={"big-button" + (selectedButtons.includes("Hatch Retract") ? " clicked" : "")} onClick={() => toggleClicked("Hatch Retract")}>Hatch Retract</button>
            <button className={"big-button" + (selectedButtons.includes("Full Retract") ? " clicked" : "")} onClick={() => toggleClicked("Full Retract")}>Full Retract</button>

          </div>


          <div className="client__card">
            <div>
              <h2>Next car:</h2>
              <p>Car Name: <b>{selectedCarName}</b></p>
              <p>Selected Services:</p>
              <ul>
                {selectedButtons.map((buttonText, index) => (
                  <li key={index}>{buttonText}</li>
                ))}
              </ul>
            </div>
            <div className="send-button-container">
              <button className="big-button send-button">SEND CAR</button>
            </div>
          </div>
        </div>
        <div className="client__buttons_small">

            <button className={"small-button" + (selectedButtons.includes("Extra Roll") ? " clicked" : "")} onClick={() => toggleClicked("Extra Roll")}>Extra Roll</button>
            <button className={"small-button" + (selectedButtons.includes("Undo Wash") ? " clicked" : "")} onClick={() => toggleClicked("Undo Wash")}>Undo Wash</button>
            <button className={"small-button" + (selectedButtons.includes("Wet Down") ? " clicked" : "")} onClick={() => toggleClicked("Wet Down")}>Wet Down</button>

          </div>
      </div>
    </div>

  );
};

export default Clients;
