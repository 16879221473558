// const navLinks = [
//   {
//     path: "/dashboard",
//     icon: "ri-apps-2-line",
//     display: "Dashboard",
//   },
//   {
//     path: "/clients",
//     icon: "ri-taxi-line",
//     display: "Clients",
//   },
//   {
//     path: "/client-history",
//     icon: "ri-shopping-bag-line",
//     display: "History",
//   },
//   {
//     path: "/settings",
//     icon: "ri-settings-2-line",
//     display: "Settings",
//   },
// ];


const navLinks = [
  {
    path: "/clients",
    icon: "ri-taxi-line",
    display: "Tunnel Entrance",
  }
];

export default navLinks;
