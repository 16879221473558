const carStaticsData = [
  {
    name: "Sat",
    week: 4000,
    prevWeek: 2400,
  },
  {
    name: "Sun",
    week: 3000,
    prevWeek: 1398,
  },
  {
    name: "Mon",
    week: 2000,
    prevWeek: 9800,
  },
  {
    name: "Tue",
    week: 2780,
    prevWeek: 3908,
  },
  {
    name: "Wed",
    week: 1890,
    prevWeek: 4800,
  },
  {
    name: "Thu",
    week: 2390,
    prevWeek: 3800,
  },
  {
    name: "Fri",
    week: 3490,
    prevWeek: 4300,
  },
];

export default carStaticsData;
